var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"books-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{staticClass:"mx-3 mt-3 py-5 px-3",attrs:{"elevation":"0","color":"primary"}},[_c('h3',{staticClass:"white--text"},[_vm._v(" Hi "+_vm._s(_vm.currentUser.full_name.split(" ")[0])+","),_c('br'),_vm._v("What would you like to learn today? ")])]),(_vm.subjectsEndpoint)?_c('intract-smart-list',{ref:"subjects-smart-list",attrs:{"disable-search":"","endpoint":_vm.subjectsEndpoint},scopedSlots:_vm._u([{key:"list-item",fn:function({ item: subject }){return [_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                name: 'All' + _vm.routeName + 'Topics',
                params: {
                  subjectId: subject.id,
                  subjectName: subject.name,
                },
              })}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(subject.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(subject.room ? subject.room.sections : "Extra Subject"))])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1),_c('v-divider',{staticClass:"mx-4"})]}}],null,false,1605928810)}):_vm._e()],1)],1),_c('choose-room-dialog',{attrs:{"visible":_vm.showRoomDialog},on:{"roomSelected":(r) => {
        _vm.selectedRoom = r;
        _vm.showRoomDialog = false;
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }